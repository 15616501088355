import React from 'react'

const SalesPage = () => {
  return (
    <div>
       <h1>Sales</h1>
    </div>
  )
}

export default SalesPage
